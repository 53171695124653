<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-7">
        <h2 class="font-weight-bolder text-dark font-size-h3 mb-0">
          {{ $t("MENU.GRAPH_DATA") }}
        </h2>
      </div>
      <div class="row">
        <div
          class="col-md-4 col-xxl-4 col-lg-12"
          v-for="item in items"
          v-bind:key="item.datagrafis_id"
        >
          <div class="card card-custom card-shadowless">
            <div class="card-body p-0">
              <div class="overlay">
                <div class="overlay-wrapper rounded bg-light text-center">
                  <b-img
                    class="mw-200 w-300px"
                    :src="createDownloadUrl(item.datagrafis_filepath)"
                  ></b-img>
                </div>
                <div class="overlay-layer">
                  <a
                    :href="createDownloadUrl(item.datagrafis_filepath)"
                    target="_blank"
                    class="btn font-weight-bolder btn-sm btn-light-primary"
                    >{{ $t("GENERAL_TEXT.DOWNLOAD") }}</a
                  >
									<a
											@click="showModal(item.datagrafis_name, createDownloadUrl(item.datagrafis_filepath))"
											class="btn font-weight-bolder btn-sm btn-light-primary"
									>{{ $t("GENERAL_TEXT.VIEW") }}</a
									>
                </div>
              </div>
              <div
                class="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column"
              >
                <a
                  href="#"
                  class="font-size-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1"
                  >{{ item.datagrafis_name }}</a
                >
                <span class="font-size-lg">{{ item.panduan_description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
		<b-modal ref="my-modal" size="xl" hide-footer :title="modalTitle">
			<b-img
					fluid
					:src="modalImageUrl"
			></b-img>
		</b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_GRAPH_DATA,
  SET_GRAPH_DATA
} from "@/core/services/store/periodic-data/graph-data.module";
import { mapGetters } from "vuex";

export default {
  name: "graph-data",
  data() {
    return {
      title: this.$t("MENU.GRAPH_DATA"),
			modalTitle: "",
			modalImageUrl: "",
      items: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PERIODIC_DATA"), route: "actual-data" },
      { title: this.$t("MENU.GRAPH_DATA") }
    ]);
    this.getItems();
  },
  computed: mapGetters(["getGraphData"]),
  methods: {
    createDownloadUrl(fileName) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "api/smartmapadmin/datagrafis/download/" +
        fileName
      );
    },
    getItems() {
      this.$store.dispatch(GET_GRAPH_DATA);
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_GRAPH_DATA) {
          this.items = this.getGraphData;
        }
      });
    },
		showModal(title, url) {
    	this.modalTitle = title;
    	this.modalImageUrl = url;
			this.$refs['my-modal'].show();
		}
  }
};
</script>

<style scoped></style>
